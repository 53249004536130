.container,
.container-fluid,
.row{
    --bs-gutter-x: 0;
}
.content-area{
    min-height: 60vh;
}
body{
    background-color: #E2E8F0;
    font-family: 'Poppins', sans-serif;
}
a{
    text-decoration: none;
    color: rgb(21 128 61 /1);
    cursor: pointer;
}
.footer{
    padding: 45px 0;
}
.top-header,
.footer{
    background-color: #14532D;
}
.top-header .list-group-item,
.footer .list-group-item,
.footer .list-group-item a{
    background-color: #14532D;
    color: #fff;
}
.transition-button{
    color: #fff;
    border-radius: 20px;
    font-weight: 700;
    letter-spacing: 1.3px;
    border: none;
    box-shadow: 12px 12px 24px #fb718566;
    transition: all .3s ease-in-out 0s;
    position: relative;
    padding: 10px 40px !important;
}
.transition-button:before {
    content: "";
    border-radius: 1000px;
    min-width: calc(100% + 12px);
    min-height: calc(100% + 12px);
    border: 3px solid #14532D;
    box-shadow: 0 10px 60px #fb7185a3;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    transition: all .3s ease-in-out 0s;
}
.transition-button:after {
    content: "";
    width: 30px;
    height: 30px;
    border-radius: 100%;
    border: 6px solid red;
    position: absolute;
    pointer-events: none;
    top: -25px;
    left: 13%;
    transform: translate(-50%,-50%);
    -webkit-animation: ring 1.5s infinite;
    animation: ring 1.5s infinite;
}
.logo{
    width:100%;
    height: 80px;
}
.payment-gateway-card .logo{
    width:100%;
    min-height: 100px;
    height:auto;
}
.print-logo{
    height:50px;
    margin-left:10px;
    margin-top:10px;
}
@keyframes ring {
    0% {
        width: 50px;
        height: 50px;
        transform: scale(0);
        opacity: 0; }
    30% {
        width: 70px;
        height: 70px;
        opacity: 1; }
    100% {
        width: 100px;
        height: 100px;
        transform: scale(1);
        opacity: 0; }
}
.transition-button:hover{
    transform: translateY(-3px);
    color: #fff;
}
.bottom-footer{
    background-color: #353148;
    color: #fff;
    padding: 8px;
    text-transform: uppercase;
}
.footer .brand-icons svg{
    font-size: 31px;
}
.footer .brand-icons .list-group-item:first-child{
    padding-left: 0;
    margin-top: 0px !important;
}
.footer .list-group-item h5{
    font-size: 16px !important;
}
.footer .list-group-item{
    padding-top: 3px;
    padding-bottom: 3px;
    font-size: 15px;
    text-transform: capitalize;
}
.footer .list-group-item:first-child{
    margin-top: 10px;
}
button.form-button{
    background-color: rgb(22 101 52/1) !important;
    padding: 10px 55px !important;
    text-transform: uppercase;
}
form .form-label span{
    color:red;
}
.tab h1{
    color: rgb(71 85 105 / 1);
    font-size: 28px;
    font-weight: 700;
}
.hide {
    display: none !important;
}
.show {
    display: block;
}
.client-details .list-group-item:hover,
.investment-details .list-group-item:hover{
    background-color: #E2E8F0 !important;
}
.client-details .list-group-item,
.client-details table tr,
.payment-frequency{
    background-color: #CAD4E0 !important;
}
.fs-3,
.payment-option p {
    color:rgb(71 85 105 / 1)
}
.payment-option p span{
    color: red;
}
.tab.file{
   height: 475px;
}
.file-upload input{
    position: absolute;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    outline: none;
    opacity: 0;
}
.file-upload{
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -100px;
    margin-left: -250px;
    width: 500px;
    height: 200px;
    border: 1px dashed rgb(2 132 199 / 1);
    border-radius: 4px;
    
}
.file-text{
    width: 100%;
    height: 100%;
    text-align: center;
    color: #000;
    margin-top: 54px;
    line-height: 28px;
}
#fileName{
    margin-top: 268px;
    background: rgb(186 230 253 /1);
    border-left: 4px solid rgba(2 132 199/1);
    font-size: 17px;
    padding: 6px;
    text-align: left;
    font-weight: bold;
}
#uploadFile{
    display: none;
}
.file-text a{
    background: rgb(2 132 199 /1);
    padding: 10px 34px;
    border-radius: 5px;
    color: #fff !important;
    text-transform: uppercase;
    text-decoration: none;
}
.faCircleCheck{
    color: rgb(22 163 74 /1);
    font-size:45px;
}
.client-details table tr:last-child{
    background-color:  rgb(3 105 161 / 1) !important;
    color: #fff;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
    -moz-appearance: textfield;
}

.custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
}
.custom-file-input::before {
    content: 'Chosse file';
    display: inline-block;
    border: 1px solid #14532D;
    border-radius: 25px;
    padding: 10px 30px;
    outline: none;
    white-space: nowrap;
    cursor: pointer;
    font-weight: 700;
    color:#14532D;
}
.custom-file-input {
    border: none !important;
    background-color:#E2E8F0;
    color:#14532D;
}
.custom-file-input:focus{
    background-color:#E2E8F0;
    box-shadow:none;
}
.step.list-group-item{
    background-color:#fff;
    color: #14532D;
}
.step.list-group-item.active{
    background-color:#14532D;
    border:1px solid #E2E8F0;
    color: #fff;
}
.step.list-group-item.finish{
    background-color:#fff;
    color: #14532D;
}
.profile .list-group-item{
    background-color: inherit;
    padding: 2px;
}
.nav.navbar-nav .nav-link{
    text-transform: capitalize;
    font-family: 'Nunito', sans-serif;
    color: #000;
}
.navbar,.navbar-brand{
    padding: 0px !important;
}
fieldset.border{
    border:2px solid #14532D !important;
    border-radius: 10px;
}
.nav-history-report p,
.gain-loss-report p,
.unit-allocation-report p,
.tax-certificate p,
.surrender-report p{
    margin: 2px !important;
    font-size: 14px;
}
.nav-history-report tfoot p,
.gain-loss-report tfoot p,
.unit-allocation-report tfoot p,
.tax-certificate tfoot p,
.surrender-report tfoot p {
    font-size: 10px;
    margin: 5px !important;
}
.was-validated .server.invalid-feedback{
    display: none !important;
}
.server.invalid-feedback{
    display: block !important;
}

.dropdown-menu[data-bs-popper]{
        left: -93px !important;
}

.report-icon{
    margin: 0px 5px;
}

.payement-gateway{
    padding-left:0px;
}
.payment-gateway-card .payement-gateway input{
    display: none;
}
.payement-gateway img{
    object-fit: contain;
    margin-left:0px;
}
.payement-gateway label{
    transition: transform .2s;
    margin-left: 10px;
    margin-right: 14px;
    margin-bottom: 20px;
}
.payement-gateway :checked + label{
    border: 2px solid rgb(59 130 246 / .5);
    border-radius: 14%;
}
.payement-gateway label:hover{
    -ms-transform: scale(1.2); 
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
}
.payment-gateway-card .list-group-item:hover{
    background-color: #fff !important;
}
.payment-gateway-card .list-group-item:first-child{
    margin-top: 10px !important;
}
.payment-gateway-card .list-group-item {
    width: calc((100%) / 5) !important;
    padding: 0px !important;
    text-align: center;
}
.common-margin{
    margin-top: 100px;
    margin-bottom: 100px;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline{
    border-color: #fff !important;
}
.MuiFormControl-root{
    width: 100%;
}
.MuiInputBase-root{
    height: 38px;
    background-color: #fff;
}

@media print {
    .printHidden{
        display: none;
    }
}
@media screen and (min-width:751px) and (max-width: 950px){
    .payment-gateway-card .list-group-item {
        width: calc((100%) / 4) !important;
    }
}
@media screen and (max-width: 550px){
    .payment-gateway-card .list-group-item {
        width: calc((100%) / 3) !important;
    }
}
@media screen and (min-width: 300px) and (max-width: 850px)  {
    .tab.file{
        height: 530px;
    }
    .tab .float-end.show{
        float: none !important;
    }
    .tab .center-align .show{
        display:inline-block !important;
    }
    .tab .center-align{
        text-align: center;
    }
}
@media screen and (max-width: 600px)  {
    .input-field{
        width: 100% !important;
    }
}
@media screen and (max-width: 500px)  {
    .top-header .list-group-item,
    .applicant-form-list .list-group-item{
        padding: 0px;
    }
}

@media screen and (max-width: 580px) {
    .res-level{
        text-align: left !important;
        padding-left: 10px;
    }
}

.auto-fill-bg{
    background-color: #e9ecef !important;
}
